export const history = {
    navigate: null,
    location: null
};

export function convertBase64(file) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
        resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
        reject(error);
        }
    })
}
export function cvtDateToString (date) {
    if (typeof date === "string") {
        return date;
    } else {
        return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
    }
}

export function cvtStringToDate (dateString) {
    const [d, m, yyyy] = dateString.split('.');
    return new Date(yyyy, m-1, d);
}

export function convertToProperDate(inputDate) {
    const date = new Date(inputDate);

    return `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

export function musclesEvaluation(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft == refMax && valueRight == refMax):
            return 'в норма';
        
        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft == valueRight && (valueLeft - refMax) <= 2 && (valueRight - refMax) <= 2) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && valueLeft == valueRight && Math.abs(valueLeft) <= 2 && Math.abs(valueRight) <= 2)):
            // return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'лека слабост';
        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft == valueRight) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && valueLeft == valueRight)):
            //return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'слабост'; 

        case ((refMax != 0 && valueLeft > refMax && valueRight == refMax && (valueLeft - refMax) <= 2) || (refMax == 0 && valueLeft < refMax && valueRight == refMax && Math.abs(valueLeft) <= 2)):
            // return <>слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'лека слабост отляво';
        case ((refMax != 0 && valueLeft > refMax && valueRight == refMax) || (refMax == 0 && valueLeft < refMax && valueRight == refMax)):
            // return <>слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'слабост отляво';

        case ((refMax != 0 && valueLeft == refMax && valueRight > refMax && (valueRight - refMax) <= 2) || (refMax == 0 && valueRight < refMax && valueLeft == refMax && Math.abs(valueRight) <= 2)):
            // return <>слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'лека слабост отдясно';
        case ((refMax != 0 && valueLeft == refMax && valueRight > refMax) || (refMax == 0 && valueLeft == refMax && valueRight < refMax)):
            // return <>слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'слабост отдясно';
        
        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax) && (valueRight - refMax) <= 2) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && Math.abs(valueLeft) < Math.abs(valueRight) && Math.abs(valueRight) <= 2)):
            // return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'лека слабост повече отдясно';
        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && Math.abs(valueLeft) < Math.abs(valueRight))):
            // return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'слабост повече отдясно';

        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax) && (valueLeft - refMax) <= 2) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && Math.abs(valueLeft) > Math.abs(valueRight) && Math.abs(valueLeft) <= 2)):
            // return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'лека слабост повече отляво';
        case ((refMax != 0 && valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)) || (refMax == 0 && valueLeft < refMax && valueRight < refMax && Math.abs(valueLeft) > Math.abs(valueRight))):
            // return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
            return 'слабост повече отляво';
        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft == valueRight) || (refMax == 0 && valueLeft == valueRight && valueLeft > refMax && valueRight > refMax)):
            return 'скъсена мускулатура';
        case ((refMax != 0 && valueLeft == refMax && valueRight < refMax) || (refMax == 0 && valueLeft == refMax && valueRight > refMax)):
            return 'скъсяване отдясно';
        case ((refMax != 0 && valueLeft < refMax && valueRight == refMax) || (refMax == 0 && valueRight == refMax && valueLeft > refMax)):
            return 'скъсяване отляво';
        case ((refMax != 0 && valueLeft > refMax && valueRight < refMax) || (refMax == 0 && valueRight > refMax && valueLeft < refMax)):
            return 'скъсяване отдясно, слабост отляво';
        case ((refMax != 0 && valueLeft < refMax && valueRight > refMax) || (refMax == 0 && valueRight < refMax && valueLeft > refMax)):
            return 'скъсяване отляво, слабост отдясно';
        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft < valueRight) || (refMax == 0 && valueRight > refMax && valueLeft > refMax && valueLeft > valueRight)):
            return 'скъсяване повече отляво';
        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft > valueRight) || (refMax == 0 && valueRight > refMax && valueLeft > refMax && valueLeft < valueRight)):
            return 'скъсяване повече отдясно';
    }
};

export function musclesEvaluationShortening(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft == refMax && valueRight == refMax):
            return '';

        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft == valueRight) || (refMax == 0 && valueLeft == valueRight && valueLeft > refMax && valueRight > refMax)):
            return 'скъсена мускулатура';
        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft < valueRight) || (refMax == 0 && valueRight > refMax && valueLeft > refMax && valueLeft > valueRight)):
            return 'скъсяване повече отляво';
        case ((refMax != 0 && valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft > valueRight) || (refMax == 0 && valueRight > refMax && valueLeft > refMax && valueLeft < valueRight)):
            return 'скъсяване повече отдясно';
        case ((refMax != 0 && valueLeft < refMax) || (refMax == 0 && valueLeft == refMax && valueLeft > refMax)):
            return 'скъсяване отляво';
        case ((refMax != 0 && valueRight < refMax) || (refMax == 0 && valueLeft == refMax && valueRight > refMax)):
            return 'скъсяване отдясно';
    }
};

export function musclesEvaluationSingle(faultyMuscle, goniometryType, refMin, refMax, value) {
    switch (true) {
        case (value === null):
            return '';
        case (value <= refMax && value >= refMin):
            return 'в норма';
        case (value > refMax && (value - refMax) <= 2):
            return `лека слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (value > refMax && (value - refMax) > 2):
            return `слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (value < refMin):
            return 'скъсена мускулатура';
    }
};

export function musclesEvaluationSingleShortening(faultyMuscle, goniometryType, refMin, refMax, value) {
    switch (true) {
        case (value === null):
            return '';
        case (value <= refMax && value >= refMin):
            return '';
        case (value < refMin):
            return 'скъсена мускулатура';
    }
};

export function musclesEvaluationSingleWeakness(faultyMuscle, goniometryType, refMin, refMax, value) {
    switch (true) {
        case (value === null):
            return '';
        case (value <= refMax && value >= refMin):
            return '';
        case (value > refMax && (value - refMax) <= 2):
            return `лека слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (value > refMax && (value - refMax) > 2):
            return `слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
    }
};

// export function musclesEvaluationLatRot(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
//     switch (true) {
//         case (valueLeft === null && valueRight === null):
//             return '';
//         case (valueLeft == refMax && valueRight == refMax):
//             return 'в норма';
//         case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight && (valueLeft - refMax) <= 3 && (valueRight - refMax) <= 3):
//             return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight):
//             return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueLeft > refMax && valueRight == refMax):
//             return <>слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueRight > refMax && valueLeft == refMax):
//             return <>слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)):
//             return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)):
//             return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
//         case (valueLeft < refMax && valueRight < refMax && valueLeft == valueRight):
//             return 'скъсена мускулатура';
//         case (valueLeft == refMax && valueRight < refMax):
//             return 'скъсяване отляво';
//         case (valueLeft < refMax && valueRight == refMax):
//             return 'скъсяване отдясно';
//         case (valueLeft < refMax && valueRight > refMax):
//             return 'скъсяване отдясно, слабост отляво';
//         case (valueRight < refMax && valueLeft > refMax):
//             return 'скъсяване отляво, слабост отдясно';
//         case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft < valueRight):
//             return 'скъсяване повече отдясно';
//         case (valueLeft < refMax && valueRight < refMax && valueLeft != valueRight && valueLeft > valueRight):
//             return 'скъсяване повече отляво';
//     }
// };

export function musclesEvaluationLatRot(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft === valueRight):
            return 'в норма';

        case (refMin == refMax && valueLeft < refMin && valueRight < refMin && valueLeft < valueRight):
            return 'скъсяване повече отдясно';
        case (refMin == refMax && valueLeft < refMin && valueRight < refMin && valueLeft > valueRight):
            return 'скъсяване повече отляво';


        case (refMin != refMax && valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft < valueRight):
            return 'скъсяване отдясно спрямо ляво';
        case (refMin != refMax && valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft > valueRight):
            return 'скъсяване отляво спрямо дясно';

            
        case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight && (valueLeft - refMax) <= 2 && (valueRight - refMax) <= 2):
            return <>лека слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight):
            return <>слабост {faultyMuscle && ` в ${faultyMuscle}`}</>;

        case (valueLeft > refMax && valueRight <= refMax && valueRight >= refMin && (valueLeft - refMax) <= 2):
            return <>лека слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight <= refMax && valueRight >=refMin):
            return <>слабост отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;

        case (valueRight > refMax && valueLeft <= refMax && valueLeft >= refMin && (valueRight - refMax) <= 2):
            return <>лека слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueRight > refMax && valueLeft <= refMax && valueLeft >=refMin):
            return <>слабост отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;

        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax) && (valueRight - refMax) <= 2):
            return <>лека слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)):
            return <>слабост повече отляво {faultyMuscle && ` в ${faultyMuscle}`}</>;

        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)) && (valueLeft - refMax) <= 2:
            return <>лека слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)):
            return <>слабост повече отдясно {faultyMuscle && ` в ${faultyMuscle}`}</>;

        case (valueLeft < refMin && valueRight < refMin && valueLeft == valueRight):
            return 'скъсена мускулатура';
        case (valueLeft >= refMin && valueLeft <= refMax && valueRight < refMin):
            return 'скъсяване отляво';
        case (valueRight >= refMin && valueRight <= refMax && valueLeft < refMin):
            return 'скъсяване отдясно';
        case (valueLeft < refMin && valueRight > refMax):
            return 'скъсяване отдясно, слабост отляво';
        case (valueRight < refMin && valueLeft > refMax):
            return 'скъсяване отляво, слабост отдясно';
        case (valueLeft < refMin && valueRight < refMin && valueLeft != valueRight && valueLeft < valueRight):
            return 'скъсяване повече отдясно';
        case (valueLeft < refMin && valueRight < refMin && valueLeft != valueRight && valueLeft > valueRight):
            return 'скъсяване повече отляво';
    }
};

export function musclesEvaluationLatRotShortening(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft === valueRight):
            return '';

        case (refMin == refMax && valueLeft < refMin && valueRight < refMin && valueLeft < valueRight):
            return 'скъсяване повече отдясно';
        case (refMin == refMax && valueLeft < refMin && valueRight < refMin && valueLeft > valueRight):
            return 'скъсяване повече отляво';

        case (refMin != refMax && valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft < valueRight):
            return 'скъсяване отдясно спрямо ляво';
        case (refMin != refMax && valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft > valueRight):
            return 'скъсяване отляво спрямо дясно';

        case (valueLeft < refMin && valueRight < refMin && valueLeft == valueRight):
            return 'скъсена мускулатура';
        case (valueLeft < refMin && valueRight < refMin && valueLeft != valueRight && valueLeft < valueRight):
            return 'скъсяване повече отдясно';
        case (valueLeft < refMin && valueRight < refMin && valueLeft != valueRight && valueLeft > valueRight):
            return 'скъсяване повече отляво';
        case (valueLeft < refMin):
            return 'скъсяване отдясно';
        case (valueRight < refMin):
            return 'скъсяване отляво';
    }
};

export function musclesEvaluationLatRotWeakness(faultyMuscle, goniometryType, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null && valueRight === null):
            return '';
        case (valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax && valueLeft === valueRight):
            return '';

        case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight && (valueLeft - refMax) <= 2 && (valueRight - refMax) <= 2):
            return `лека слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (valueLeft > refMax && valueRight > refMax && valueLeft == valueRight):
            return `слабост${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;

        case (valueLeft > refMax && valueRight <= refMax && valueRight >= refMin && (valueLeft - refMax) <= 2):
            return `лека слабост отдясно${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (valueLeft > refMax && valueRight <= refMax && valueRight >=refMin):
            return `слабост отдясно${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;

        case (valueRight > refMax && valueLeft <= refMax && valueLeft >= refMin && (valueRight - refMax) <= 2):
            return `лека слабост отляво${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (valueRight > refMax && valueLeft <= refMax && valueLeft >=refMin):
            return `слабост отляво${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;

        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax) && (valueRight - refMax) <= 2):
            return `лека слабост повече отляво${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) < (valueRight - refMax)):
            return `слабост повече отляво${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;

        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)) && (valueLeft - refMax) <= 2:
            return `лека слабост повече отдясно${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
        case (valueLeft > refMax && valueRight > refMax && valueLeft != valueRight && (valueLeft - refMax) > (valueRight - refMax)):
            return `слабост повече отдясно${faultyMuscle ? ` в ${faultyMuscle}` : ''}`;
    }
};

// export function goniometryEvaluation(joint, goniometryType1, goniometryType2, refGonType1Max, refGonType2Max, gonType1Value, gonType2Value) {
//     if (goniometryType1 != 'екстензия' && goniometryType1 != 'плантарна флексия') {
//         switch (true) {
//             case (gonType1Value === null || gonType2Value === null):
//                 return '';
//             case (gonType1Value >= refGonType1Max && gonType2Value >= refGonType2Max):
//                 return <span className='alert-ok'>в норма</span>;
//             case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение</span>;
//             case (gonType1Value < refGonType1Max && gonType2Value >= refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
//             case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
//     }
//     } else {
//         switch (true) {
//             case (gonType1Value === null || gonType2Value === null):
//                 return '';
//             case (gonType1Value == refGonType1Max && gonType2Value == refGonType2Max):
//                 return <span className='alert-ok'>в норма</span>;
//             case (gonType1Value > refGonType1Max && gonType2Value > refGonType2Max):
//                 return <span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}, {gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
//             case (gonType1Value > refGonType1Max && gonType2Value == refGonType2Max):
//                 return <span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</span>;
//             case (gonType1Value == refGonType1Max && gonType2Value > refGonType2Max):
//                 return <span className='alert-def'>{gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
//             case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение</span>;
//             // case (gonType1Value < refGonType1Max && gonType2Value == refGonType2Max):
//             case (gonType1Value < refGonType1Max && gonType2Value >= refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
//             case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
//                 return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
//         }

//     }
// };

// export function goniometryEvaluation(joint, goniometryType1, goniometryType2, refGonType1Max, refGonType2Max, gonType1Value, gonType2Value) {
//     let message = [];
//     if (goniometryType1 != 'екстензия' && goniometryType1 != 'плантарна флексия') {
//         switch (true) {
//             case (gonType1Value === null || gonType2Value === null):
//                 message.push('');
//                 break;
//             case (gonType1Value >= refGonType1Max && gonType2Value >= refGonType2Max):
//                 message.push(<span className='alert-ok'>в норма</span>);
//                 break;
//             case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение</span>);
//                 break;
//             case (gonType1Value < refGonType1Max && gonType2Value >= refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>);
//                 break;
//             case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>);
//                 break;
//     }
//     } else {
//         switch (true) {
//             case (gonType1Value === null || gonType2Value === null):
//                 message.push('');
//                 break;
//             case ((gonType1Value == refGonType1Max && gonType2Value == refGonType2Max) || (joint === 'става на китката' && gonType1Value > refGonType1Max && gonType2Value > refGonType2Max)):
//                 message.push(<span className='alert-ok'>в норма</span>);
//                 break;
//             case (gonType1Value > refGonType1Max && gonType2Value > refGonType2Max):
//                 message.push(<span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}, {gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>);
//                 break;
//             case (gonType1Value > refGonType1Max && gonType2Value == refGonType2Max):
//                 message.push(<span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</span>);
//                 break;
//             case (gonType1Value == refGonType1Max && gonType2Value > refGonType2Max):
//                 message.push(<span className='alert-def'>{gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>);
//                 break;
//             case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение</span>);
//                 break;
//             // case (gonType1Value < refGonType1Max && gonType2Value == refGonType2Max):
//             case (gonType1Value < refGonType1Max && gonType2Value == refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>);
//                 break;
//             case (gonType1Value < refGonType1Max && gonType2Value > refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType1}`} {joint != 'става на китката' && <>, {gonType2Value-refGonType2Max}° хипер{`${goniometryType2}`}</>}</span>);
//                 break;
//             case (gonType1Value == refGonType1Max && gonType2Value < refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>);
//                 break;
//             case (gonType1Value > refGonType1Max && gonType2Value < refGonType2Max):
//                 message.push(<span className='alert-def'>намален обем на движение при {`${goniometryType2}`} {joint != 'става на китката' && <>, {gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</>}</span>);
//                 break;
//         }

//     }
//     return <>{message}</>;
// };

export function goniometryEvaluation(joint, goniometryType1, goniometryType2, refGonType1Max, refGonType2Max, gonType1Value, gonType2Value) {
    // if (goniometryType1 != 'екстензия' && goniometryType1 != 'плантарна флексия') {
    //     switch (true) {
    //         case (gonType1Value === null || gonType2Value === null):
    //             return '';
    //         case (gonType1Value >= refGonType1Max && gonType2Value >= refGonType2Max):
    //             return <span className='alert-ok'>в норма</span>;
    //         case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
    //             return <span className='alert-def'>намален обем на движение</span>;
    //         case (gonType1Value < refGonType1Max && gonType2Value >= refGonType2Max):
    //             return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
    //         case (gonType1Value >= refGonType1Max && gonType2Value < refGonType2Max):
    //             return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
    // }
    // } else {
        if (goniometryType1 == 'екстензия' && refGonType1Max == 0) {
            switch (true) {
                case (gonType1Value === null || gonType2Value === null):
                    return '';
                case (gonType1Value == refGonType1Max && gonType2Value == refGonType2Max):
                    return <span className='alert-ok'>в норма</span>;
                case (gonType1Value < refGonType1Max && gonType2Value > refGonType2Max):
                    return <span className='alert-def'>{Math.abs(gonType1Value)}° хипер{`${goniometryType1}`}, {gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
                case (gonType1Value < refGonType1Max && gonType2Value == refGonType2Max):
                    return <span className='alert-def'>{Math.abs(gonType1Value)}° хипер{`${goniometryType1}`}</span>;
                case (gonType1Value == refGonType1Max && gonType2Value > refGonType2Max):
                    return <span className='alert-def'>{gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
                case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
                    return <span className='alert-def'>{Math.abs(gonType1Value)}° хипер{`${goniometryType1}`}, намален обем на движение при {`${goniometryType2}`}</span>;
                case (gonType1Value > refGonType1Max && gonType2Value == refGonType2Max):
                    return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
                case (gonType1Value > refGonType1Max && gonType2Value > refGonType2Max):
                    return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}<br /> {<>{gonType2Value-refGonType2Max}° хипер{`${goniometryType2}`}</>}</span>;
                case (gonType1Value == refGonType1Max && gonType2Value < refGonType2Max):
                    return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
                case (gonType1Value > refGonType1Max && gonType2Value < refGonType2Max):
                    return <span className='alert-def'>намален обем на движение</span>;
            }
        } else {
        switch (true) {
            case (gonType1Value === null || gonType2Value === null):
                return '';
            //case ((gonType1Value == refGonType1Max && gonType2Value == refGonType2Max) || (joint === 'става на китката' && gonType1Value > refGonType1Max && gonType2Value > refGonType2Max)):
            case (gonType1Value == refGonType1Max && gonType2Value == refGonType2Max):
                return <span className='alert-ok'>в норма</span>;
            case (gonType1Value > refGonType1Max && gonType2Value > refGonType2Max):
                return <span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}, {gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
            case (gonType1Value > refGonType1Max && gonType2Value == refGonType2Max):
                return <span className='alert-def'>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</span>;
            case (gonType1Value == refGonType1Max && gonType2Value > refGonType2Max):
                return <span className='alert-def'>{gonType2Value - refGonType2Max}° хипер{`${goniometryType2}`}</span>;
            case (gonType1Value < refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение</span>;
            case (gonType1Value < refGonType1Max && gonType2Value == refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}</span>;
            case (gonType1Value < refGonType1Max && gonType2Value > refGonType2Max):
                // return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`} {joint != 'става на китката' && <>, {gonType2Value-refGonType2Max}° хипер{`${goniometryType2}`}</>}</span>;
                return <span className='alert-def'>намален обем на движение при {`${goniometryType1}`}<br /> {<>{gonType2Value-refGonType2Max}° хипер{`${goniometryType2}`}</>}</span>;
            case (gonType1Value == refGonType1Max && gonType2Value < refGonType2Max):
                return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}</span>;
            case (gonType1Value > refGonType1Max && gonType2Value < refGonType2Max):
                // return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`} {joint != 'става на китката' && <>, {gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</>}</span>;
                return <span className='alert-def'>намален обем на движение при {`${goniometryType2}`}<br /> {<>{gonType1Value-refGonType1Max}° хипер{`${goniometryType1}`}</>}</span>;
        }
    }
};

// export function goniometryLeftRight(joint, directionL, directionR, refMin, refMax, valueLeft, valueRight) {
//     switch (true) {
//         case (valueLeft === null || valueRight === null):
//             return '';
//         case (valueLeft > refMax && valueRight > refMax):
//             return <span className='alert-ok'>в норма</span>;
//         case (valueLeft >= refMin && valueRight >= refMin && valueLeft <= refMax && valueRight <= refMax):
//             return <span className='alert-ok'>в норма</span>;
//         case (valueLeft < refMin && valueRight < refMin):
//             return <span className='alert-def'>намален обем на движение</span>;
//         case (valueLeft < refMin && valueRight >= refMin && valueRight <= refMax):
//             return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
//         case (valueRight < refMin && valueLeft >= refMin && valueLeft <= refMax):
//             return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
//     }
// };

export function goniometryLeftRight(joint, directionL, directionR, refMin, refMax, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null || valueRight === null):
            return '';
        case (valueLeft >= refMin && valueRight >= refMin):
            return <span className='alert-ok'>в норма</span>;
        case (valueLeft < refMin && valueRight < refMin):
            return <span className='alert-def'>намален обем на движение</span>;
        case (valueLeft < refMin && valueRight >= refMin):
            return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
        case (valueRight < refMin && valueLeft >= refMin):
            return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
    }
};

export function goniometryLeftRightClassName(refMin, refMax, value, otherValue) {
    switch (true) {
        case (value === null || otherValue === null):
            return '';
        case (goniometryLeftRight('латерални наклони гръбначен стълб', 'наляво', 'надясно', refMin, refMax, value, otherValue).props.children.toString().includes('в норма') && value < otherValue):
            return 'alert-smallerValue';
        case (!goniometryLeftRight('латерални наклони гръбначен стълб', 'наляво', 'надясно', refMin, refMax, value, otherValue).props.children.toString().includes('в норма') && value <= otherValue):
            return 'alert-def';
        case (!goniometryLeftRight('латерални наклони гръбначен стълб', 'наляво', 'надясно', refMin, refMax, value, otherValue).props.children.toString().includes('в норма') && value > otherValue):
            return '';
    }
};

export function goniometryLeftRightSingleRef(joint, directionL, directionR, ref, valueLeft, valueRight) {
    switch (true) {
        case (valueLeft === null || valueRight === null):
            return '';
        case (valueLeft >= ref && valueRight >= ref):
            return <span className='alert-ok'>в норма</span>;
        case (valueLeft < ref && valueRight < ref):
            return <span className='alert-def'>намален обем на движение</span>;
        case (valueLeft < ref && valueRight >= ref):
            return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
        case (valueRight < ref && valueLeft >= ref):
            return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
    }
};

export function goniometryLeftRightSingleRefClassName(ref, value, otherValue) {
    switch (true) {
        case (value === null || otherValue === null):
            return '';
        case (goniometryLeftRightSingleRef('латерални наклони гръбначен стълб', 'наляво', 'надясно', ref, value, otherValue).props.children.toString().includes('в норма') && value < otherValue):
            return 'alert-smallerValue';
        case (!goniometryLeftRightSingleRef('латерални наклони гръбначен стълб', 'наляво', 'надясно', ref, value, otherValue).props.children.toString().includes('в норма') && value <= otherValue):
            return 'alert-def';
        case (!goniometryLeftRightSingleRef('латерални наклони гръбначен стълб', 'наляво', 'надясно', ref, value, otherValue).props.children.toString().includes('в норма') && value > otherValue):
            return '';
    }
};

// export function goniometryLeftRightSingleRef(joint, directionL, directionR, ref, valueLeft, valueRight) {
//     switch (true) {
//         case (valueLeft === null || valueRight === null):
//             return '';
//         case (valueLeft == ref && valueRight == ref):
//             return <span className='alert-ok'>в норма</span>;
//         case (valueLeft < ref && valueRight < ref):
//             return <span className='alert-def'>намален обем на движение</span>;
//         case (valueLeft > ref && valueRight > ref):
//             return <span className='alert-def'>увеличен обем на движение</span>;
//         case (valueLeft < ref && valueRight == ref):
//             return <span className='alert-def'>намален обем на движение {`${directionL}`}</span>;
//         case (valueRight < ref && valueLeft == ref):
//             return <span className='alert-def'>намален обем на движение {`${directionR}`}</span>;
//         case (valueLeft > ref && valueRight == ref):
//             return <span className='alert-def'>увеличен обем на движение {`${directionL}`}</span>;
//         case (valueRight > ref && valueLeft == ref):
//             return <span className='alert-def'>увеличен обем на движение {`${directionR}`}</span>;
//         case (valueLeft < ref && valueRight > ref):
//             return <span className='alert-def'>намален обем на движение {`${directionL}`}, увеличен обем на движение {`${directionR}`}</span>;
//         case (valueRight < ref && valueLeft > ref):
//             return <span className='alert-def'>намален обем на движение {`${directionR}`}, увеличен обем на движение {`${directionL}`}</span>;
//     }
// };

export function excapeSlashes(str) {
    if (str === undefined || str === null) {
        return str;
    }
    return str.replace(/\//g, '\\/');
}